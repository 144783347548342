export const messageHandler = (data, product, user, setAlert, setViewProduct, type) => {
    if (user) {
        console.log('message -', data)
        //console.log('user inside', user)
        let user_id = user.id
        const index = product.findIndex(
            (s) => s.id === parseInt(data.id || data.project_id || data.pid, 10),
        )
        let productToChange = product[index]
        //console.log('data', data)
        if (index !== -1) {
            if (type === 'realclosedupdates') {
                if (data.usr !== '') {
                    if (user_id === parseInt(data.bpop_cbidder)) {
                        product[index] = {
                            ...productToChange,
                            market_status: 'sold',
                            bidtopstatus: 'won',
                            buynowamount: data.buynowamount,
                            cbidtext: 'Sold Price',
                            buynowed: data.bpop_cbidder,
                            buynowpaid: 0,
                            common_invoice: data.common_invoice,
                            socket_change: 1,
                        }
                        if (global.storeConfigration?.end_timer_move_item_to_lost?.value == 1) {
                            var arr_index = product
                            let element = arr_index.splice(index, 1)[0]
                            arr_index.push(element)
                            setViewProduct([...arr_index])
                        } else {
                            setViewProduct([...product])
                        }
                    } else if (parseInt(productToChange.bid_or_not) > 0) {
                        product[index] = {
                            ...productToChange,
                            market_status: 'sold',
                            bidtopstatus: 'lost',
                            buynowamount: data.buynowamount,
                            cbidtext: 'Sold Price',
                            buynowed: data.bpop_cbidder,
                            buynowpaid: 0,
                            socket_change: 1,
                        }
                        if (global.storeConfigration?.end_timer_move_item_to_lost?.value == 1) {
                            var arr_index = product
                            let element = arr_index.splice(index, 1)[0]
                            arr_index.push(element)
                            setViewProduct([...arr_index])
                        } else {
                            setViewProduct([...product])
                        }
                    } else {
                        product[index] = {
                            ...productToChange,
                            market_status: 'sold',
                            buynowamount: data.buynowamount,
                            cbidtext: 'Sold Price',
                            socket_change: 1,
                        }
                        if (global.storeConfigration?.end_timer_move_item_to_lost?.value == 1) {
                            var arr_index = product
                            let element = arr_index.splice(index, 1)[0]
                            arr_index.push(element)
                            setViewProduct([...arr_index])
                        } else {
                            setViewProduct([...product])
                        }
                    }
                } else {
                    if (parseInt(productToChange.bid_or_not) > 0) {
                        product[index] = {
                            ...productToChange,
                            market_status: 'closed',
                            bidtopstatus: 'lost',
                            socket_change: 1,
                        }
                        if (global.storeConfigration?.end_timer_move_item_to_lost?.value == 1) {
                            var arr_index = product
                            let element = arr_index.splice(index, 1)[0]
                            arr_index.push(element)
                            setViewProduct([...arr_index])
                        } else {
                            setViewProduct([...product])
                        }
                    } else {
                        product[index] = {
                            ...productToChange,
                            market_status: 'closed',
                            socket_change: 1,
                        }
                        if (global.storeConfigration?.end_timer_move_item_to_lost?.value == 1) {
                            var arr_index = product
                            let element = arr_index.splice(index, 1)[0]
                            arr_index.push(element)
                            setViewProduct([...arr_index])
                        } else {
                            setViewProduct([...product])
                        }
                    }
                }
            } else if (type === 'bidAddtime') {
                let date_closed = product[index].date_closed
                if (parseInt(data.bpop_belowFive) === 1) {
                    date_closed = data.bpop_belowFiveIncrement
                }
                if (data.status === 'failed' && user_id === parseInt(data.bpop_cbidder)) {
                    setAlert(data.error, 'error')
                }
                if (data.status !== 'failed') {
                    let maxbidamt = product[index].maxbidamt
                    let cbidnot = product[index].cbidnot
                    // console.log(product[index], 'product[index]')
                    if (user_id === parseInt(data.bpop_cbidder)) {
                        setAlert('Bid placed successfully', 'success')
                        if (maxbidamt < parseFloat(data.bidAmount)) {
                            maxbidamt = data.bidAmount
                        }
                        if (data.bpop_bidcount == 1 && data.bpop_wprice == 1) {
                            maxbidamt = data.bpop_wprice_morehigh
                        }
                        if (data.bpop_orgwsprice && data.bpop_orgwsprice != '') {
                            maxbidamt = data.bpop_orgwsprice
                        }

                        if (parseInt(user_id) === parseInt(data.bpop_higher)) {
                            if (
                                parseInt(product[index].rprice || 0) <= parseInt(data.bpop_wprice)
                            ) {
                                if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                    data.bpop_bidstatus = `You are winning this item at $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()}. `
                                } else {
                                    data.bpop_bidstatus = `You are winning this item at $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()}. Your autoBid of $${parseFloat(
                                        data.bpop_wprice_morehigh,
                                    ).toUSFormat()} has been placed successfully.`
                                }
                                setAlert('You are winning this item!', 'success')
                            } else {
                                if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                    data.bpop_bidstatus = `Your bid of $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                } else {
                                    data.bpop_bidstatus = `Your bid of $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()} and auto bid of $${parseFloat(
                                        data.bpop_wprice_morehigh,
                                    ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                }
                                setAlert(
                                    'You are losing this item. Because of reserve price not yet met!',
                                    'error',
                                )
                            }
                        } else if (data.bpop_bidstatus.includes('losing')) {
                            data.bpop_bidstatus = `You've been outbid on this item. Current leading bid is $${parseFloat(
                                data.bpop_wprice,
                            ).toUSFormat()}`
                            setAlert("You've been outbid on this item.", 'error')
                        }

                        product[index] = {
                            ...productToChange,
                            next_bid: data.bpop_cuser_nextbid,
                            wprice: data.bpop_wprice,
                            latestbid: data.bpop_wprice,
                            bidtopstatus: data.bpop_bidstatus,
                            nobids: data.bpop_bidcount,
                            bidcount: data.bpop_bidcount,
                            incrementamt: data.bpop_cuser_increment,
                            bid_or_not: 1,
                            date_closed,
                            maxbidamt: maxbidamt,
                            latestbid_user: data.bpop_higher,
                            cbidtext: 'Current Bid',
                            socket_change: 1,
                        }
                        setViewProduct([...product])
                    } else {
                        // console.log('coming inside 3333', cbidnot, productToChange.bid_or_not)
                        if (parseInt(productToChange.bid_or_not) > 0 || parseInt(cbidnot) > 0) {
                            // console.log('coming inside 55555', user_id, productToChange, data)
                            if (user_id === parseInt(data.bpop_higher)) {
                                // console.log('123123213', parseInt(productToChange.next_bid))
                                if (
                                    parseInt(productToChange.next_bid) < parseInt(data.bpop_nextbid)
                                ) {
                                    // console.log('faasdfsadf')

                                    if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                        data.bidtopstatus = `You are winning this item at $${parseFloat(
                                            data.bpop_wprice,
                                        ).toUSFormat()}.`
                                    } else {
                                        data.bidtopstatus = `You are winning this item at $${parseFloat(
                                            data.bpop_wprice,
                                        ).toUSFormat()}. Your autoBid of $${parseFloat(
                                            data.bpop_wprice_morehigh,
                                        ).toUSFormat()} has been placed successfully.`
                                    }
                                    product[index] = {
                                        ...productToChange,
                                        latestbid: data.bpop_wprice,
                                        nobids: data.bpop_bidcount,
                                        bidcount: data.bpop_bidcount,
                                        incrementamt: productToChange.incrementamt,
                                        next_bid: data.bpop_nextbid_org,
                                        wprice: data.bpop_wprice,
                                        bidtopstatus: data.bidtopstatus,
                                        bid_count: data.bpop_bidcount,
                                        latestbid_user: data.bpop_higher,
                                        date_closed,
                                        socket_change: 1,
                                    }
                                    setViewProduct([...product])
                                } else {
                                    if (
                                        parseInt(product[index].rprice || 0) <=
                                        parseInt(data.bpop_wprice)
                                    ) {
                                        if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                            data.bidtopstatus = `You are winning this item at $${parseFloat(
                                                data.bpop_wprice,
                                            ).toUSFormat()}.`
                                        } else {
                                            data.bidtopstatus = `You are winning this item at $${parseFloat(
                                                data.bpop_wprice,
                                            ).toUSFormat()}. Your autoBid of $${parseFloat(
                                                data.bpop_wprice_morehigh,
                                            ).toUSFormat()} has been placed successfully.`
                                        }
                                    } else {
                                        if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                            data.bidtopstatus = `Your bid of $${parseFloat(
                                                data.bpop_wprice,
                                            ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                        } else {
                                            data.bidtopstatus = `Your bid of $${parseFloat(
                                                data.bpop_wprice,
                                            ).toUSFormat()} and auto bid of $${parseFloat(
                                                data.bpop_wprice_morehigh,
                                            ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                        }
                                    }
                                    product[index] = {
                                        ...productToChange,
                                        latestbid: data.bpop_wprice,
                                        nobids: data.bpop_bidcount,
                                        bidcount: data.bpop_bidcount,
                                        incrementamt: productToChange.incrementamt,
                                        wprice: data.bpop_wprice,
                                        bidtopstatus: data.bidtopstatus,
                                        bid_count: data.bpop_bidcount,
                                        latestbid_user: data.bpop_higher,
                                        date_closed,
                                        socket_change: 1,
                                    }
                                    setViewProduct([...product])
                                }
                            } else {
                                product[index] = {
                                    ...productToChange,
                                    latestbid: data.bpop_wprice,
                                    nobids: data.bpop_bidcount,
                                    bidcount: data.bpop_bidcount,
                                    incrementamt: data.bpop_increment,
                                    next_bid: data.bpop_nextbid_org,
                                    wprice: data.bpop_wprice,
                                    latestbid_user: data.bpop_higher,
                                    bid_count: data.bpop_bidcount,
                                    bidtopstatus: `You've been outbid on this item. Current leading bid is $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()}`,
                                    bid_or_not: 1,
                                    date_closed,
                                    socket_change: 1,
                                }
                                setViewProduct([...product])
                            }
                        } else {
                            // console.log('coming inside 6777777', data)
                            product[index] = {
                                ...productToChange,
                                latestbid: data.bpop_wprice,
                                nobids: data.bpop_bidcount,
                                bidcount: data.bpop_bidcount,
                                incrementamt: data.bpop_increment,
                                next_bid: data.bpop_nextbid_org,
                                wprice: data.bpop_wprice,
                                bid_count: data.bpop_bidcount,
                                cbidtext: 'Current Bid',
                                date_closed,
                                socket_change: 1,
                            }
                            setViewProduct([...product])
                        }
                    }
                    // getBidHistoryProduct({ product_id: project_id })
                }
            } else if (type === 'watchlistAdded') {
                product[index] = {
                    ...productToChange,
                    watchlistid: data.status === 'added' ? 1 : 0,
                    socket_change: 1,
                }
                setViewProduct([...product])
            } else if (type === 'cancelbidemitted') {
                console.log(data, 'cancellllllllllll2')
                if (Object.keys(data).length) {
                    // console.log('current id vs new lot id', productToChange.id)

                    if (parseInt(data.project_id) === parseInt(productToChange.id)) {
                        product[index] = {
                            ...productToChange,
                            nobids: data.bpop_bidcount,
                            wprice: data.bpop_wprice,
                            next_bid:
                                data.bpop_bidcount == 0 ? data.bpop_wprice : data.bpop_nextbid,
                            latestbid: data.bpop_bidcount == 0 ? null : data.bpop_wprice,
                            bid_count: data.bpop_bidcount,
                            socket_change: 1,
                        }
                        console.log('product in cancel bid', product)
                        setViewProduct([...product])
                    }
                }
            } else if (type === 'buyer_assurance') {
                if (Object.keys(data).length) {
                    if (
                        parseInt(data.project_id) === parseInt(productToChange.id) &&
                        parseInt(data.user_id) === parseInt(user_id)
                    ) {
                        product[index] = {
                            ...productToChange,
                            socket_change: 0,
                            buyerass: parseInt(data.buyerass),
                        }
                        setViewProduct([...product])
                    }
                }
            }
        }
    }
}

export const messageHandlerDashboard = (
    data,
    product,
    user,
    setAlert,
    setViewProduct,
    type,
    from,
) => {
    if (!user) return

    console.log(`message - ${from}`, data)
    const userId = user.id
    const productIndex = product.findIndex(
        (s) => s.id === parseInt(data.id || data.project_id || data.pid, 10),
    )

    if (productIndex === -1) return

    const productToChange = product[productIndex]
    console.log('product to change', productToChange)

    const updateProductList = (updatedProduct) => {
        const newProductList = [...product]
        newProductList[productIndex] = updatedProduct

        if (global.storeConfigration?.end_timer_move_item_to_lost?.value === 1) {
            const movedItem = newProductList.splice(productIndex, 1)[0]
            newProductList.push(movedItem)
        }

        setViewProduct(newProductList)
    }

    const handleRealClosedUpdates = () => {
        const isCurrentUser = userId === parseInt(data.bpop_cbidder)
        const baseProductUpdate = {
            ...productToChange,
            market_status: 'sold',
            buynowamount: data.buynowamount,
            cbidtext: 'Sold Price',
            socket_change: 1,
        }

        if (data.usr !== '') {
            if (isCurrentUser) {
                baseProductUpdate.bidtopstatus = 'won'
                baseProductUpdate.buynowed = data.bpop_cbidder
                baseProductUpdate.buynowpaid = 0
                baseProductUpdate.common_invoice = data.common_invoice
            } else if (parseInt(productToChange.bid_or_not) > 0) {
                baseProductUpdate.bidtopstatus = 'lost'
                baseProductUpdate.buynowed = data.bpop_cbidder
                baseProductUpdate.buynowpaid = 0
            }
        } else {
            baseProductUpdate.market_status = 'closed'
            baseProductUpdate.bidtopstatus = parseInt(productToChange.bid_or_not) > 0 ? 'lost' : ''
        }

        updateProductList(baseProductUpdate)
    }

    const handleBidAddTime = () => {
        const productToChange = { ...product[productIndex] }
        let updatedDateClosed = productToChange.date_closed

        if (parseInt(data.bpop_belowFive) === 1) {
            updatedDateClosed = data.bpop_belowFiveIncrement
        }

        if (data.status === 'failed' && userId === parseInt(data.bpop_cbidder)) {
            setAlert(data.error, 'error')
            return
        }

        if (data.status !== 'failed') {
            let updatedMaxBid = productToChange.maxbidamt || 0
            const isCurrentUser = userId === parseInt(data.bpop_cbidder)

            if (isCurrentUser) {
                setAlert('Bid placed successfully', 'success')
                updatedMaxBid = Math.max(updatedMaxBid, parseFloat(data.bidAmount))

                if (data.bpop_bidcount == 1 && data.bpop_wprice == 1) {
                    updatedMaxBid = data.bpop_wprice_morehigh
                }

                if (data.bpop_orgwsprice && data.bpop_orgwsprice !== '') {
                    updatedMaxBid = data.bpop_orgwsprice
                }

                if (parseInt(userId) === parseInt(data.bpop_higher)) {
                    if (parseInt(productToChange.rprice || 0) <= parseInt(data.bpop_wprice)) {
                        productToChange.bidtopstatus =
                            data.bpop_wprice === data.bpop_wprice_morehigh
                                ? `You are winning this item at $${parseFloat(
                                      data.bpop_wprice,
                                  ).toUSFormat()}.`
                                : `You are winning this item at $${parseFloat(
                                      data.bpop_wprice,
                                  ).toUSFormat()}. Your autoBid of $${parseFloat(
                                      data.bpop_wprice_morehigh,
                                  ).toUSFormat()} has been placed successfully.`
                        setAlert('You are winning this item!', 'success')
                    } else {
                        productToChange.bidtopstatus =
                            data.bpop_wprice === data.bpop_wprice_morehigh
                                ? `Your bid of $${parseFloat(
                                      data.bpop_wprice,
                                  ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                : `Your bid of $${parseFloat(
                                      data.bpop_wprice,
                                  ).toUSFormat()} and auto bid of $${parseFloat(
                                      data.bpop_wprice_morehigh,
                                  ).toUSFormat()} on this item placed, but reserve price not yet met.`
                        setAlert('You are losing this item. Reserve price not yet met!', 'error')
                    }
                }

                // Update the product in the array
                product[productIndex] = {
                    ...productToChange,
                    next_bid: data.bpop_cuser_nextbid,
                    wprice: data.bpop_wprice,
                    latestbid: data.bpop_wprice,
                    bidtopstatus: productToChange.bidtopstatus,
                    nobids: data.bpop_bidcount,
                    bidcount: data.bpop_bidcount,
                    incrementamt: data.bpop_cuser_increment,
                    bid_or_not: 1,
                    date_closed: updatedDateClosed,
                    maxbidamt: updatedMaxBid,
                    latestbid_user: data.bpop_higher,
                    highest_bidder_user_id: data.bpop_higher,
                    cbidtext: 'Current Bid',
                    socket_change: 1,
                }

                // Set the updated product array
                setViewProduct([...product])
            } else {
                if (
                    parseInt(productToChange.bid_or_not) > 0 ||
                    parseInt(productToChange.cbidnot) > 0
                ) {
                    if (parseInt(userId) === parseInt(data.bpop_higher)) {
                        // Current user logic
                        if (parseInt(productToChange.next_bid) < parseInt(data.bpop_nextbid)) {
                            if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                data.bidtopstatus = `You are winning this item at $${parseFloat(
                                    data.bpop_wprice,
                                ).toUSFormat()}.`
                            } else {
                                data.bidtopstatus = `You are winning this item at $${parseFloat(
                                    data.bpop_wprice,
                                ).toUSFormat()}. Your autoBid of $${parseFloat(
                                    data.bpop_wprice_morehigh,
                                ).toUSFormat()} has been placed successfully.`
                            }
                            product[productIndex] = {
                                ...productToChange,
                                latestbid: data.bpop_wprice,
                                nobids: data.bpop_bidcount,
                                bidcount: data.bpop_bidcount,
                                incrementamt: productToChange.incrementamt,
                                next_bid: data.bpop_nextbid_org,
                                wprice: data.bpop_wprice,
                                bidtopstatus: data.bidtopstatus,
                                bid_count: data.bpop_bidcount,
                                latestbid_user: data.bpop_higher,
                                highest_bidder_user_id: data.bpop_higher,
                                socket_change: 1,
                            }
                            setViewProduct([...product])
                        } else {
                            if (
                                parseInt(product[productIndex].rprice || 0) <=
                                parseInt(data.bpop_wprice)
                            ) {
                                if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                    data.bidtopstatus = `You are winning this item at $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()}.`
                                } else {
                                    data.bidtopstatus = `You are winning this item at $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()}. Your autoBid of $${parseFloat(
                                        data.bpop_wprice_morehigh,
                                    ).toUSFormat()} has been placed successfully.`
                                }
                            } else {
                                if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                    data.bidtopstatus = `Your bid of $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                } else {
                                    data.bidtopstatus = `Your bid of $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()} and auto bid of $${parseFloat(
                                        data.bpop_wprice_morehigh,
                                    ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                }
                            }
                            product[productIndex] = {
                                ...productToChange,
                                latestbid: data.bpop_wprice,
                                nobids: data.bpop_bidcount,
                                bidcount: data.bpop_bidcount,
                                incrementamt: productToChange.incrementamt,
                                wprice: data.bpop_wprice,
                                bidtopstatus: data.bidtopstatus,
                                bid_count: data.bpop_bidcount,
                                latestbid_user: data.bpop_higher,
                                highest_bidder_user_id: data.bpop_higher,
                                socket_change: 1,
                            }
                            setViewProduct([...product])
                        }
                    } else {
                        // Non-current user logic
                        product[productIndex] = {
                            ...productToChange,
                            latestbid: data.bpop_wprice,
                            nobids: data.bpop_bidcount,
                            bidcount: data.bpop_bidcount,
                            incrementamt: data.bpop_increment,
                            next_bid: data.bpop_nextbid_org,
                            wprice: data.bpop_wprice,
                            bid_count: data.bpop_bidcount,
                            highest_bidder_user_id: data.bpop_higher,
                            bidtopstatus: `You've been outbid on this item. Current leading bid is $${parseFloat(
                                data.bpop_wprice,
                            ).toUSFormat()}`,
                            bid_or_not: 1,
                            socket_change: 1,
                        }
                        setViewProduct([...product])
                    }
                } else {
                    // Catch-all else block
                    product[productIndex] = {
                        ...productToChange,
                        latestbid: data.bpop_wprice,
                        nobids: data.bpop_bidcount,
                        bidcount: data.bpop_bidcount,
                        incrementamt: data.bpop_increment,
                        next_bid: data.bpop_nextbid_org,
                        wprice: data.bpop_wprice,
                        bid_count: data.bpop_bidcount,
                        highest_bidder_user_id: data.bpop_higher,
                        cbidtext: 'Current Bid',
                        socket_change: 1,
                    }
                    setViewProduct([...product])
                }
            }
        }
    }

    const handleWatchlistUpdate = () => {
        const watchlistUpdate = {
            ...productToChange,
            watchlistid: data.status === 'added' ? 1 : 0,
            socket_change: 1,
        }
        updateProductList(watchlistUpdate)
    }

    const handleCancelBidEmitted = () => {
        if (parseInt(data.project_id) === parseInt(productToChange.id)) {
            const cancelBidUpdate = {
                ...productToChange,
                nobids: data.bpop_bidcount,
                wprice: data.bpop_wprice,
                next_bid: data.bpop_bidcount === 0 ? data.bpop_wprice : data.bpop_nextbid,
                latestbid: data.bpop_bidcount === 0 ? null : data.bpop_wprice,
                bid_count: data.bpop_bidcount,
                socket_change: 1,
            }
            updateProductList(cancelBidUpdate)
        }
    }

    const handleBuyerAssurance = () => {
        if (
            parseInt(data.project_id) === parseInt(productToChange.id) &&
            parseInt(data.user_id) === parseInt(userId)
        ) {
            const buyerAssuranceUpdate = {
                ...productToChange,
                socket_change: 0,
                buyerass: parseInt(data.buyerass),
            }
            updateProductList(buyerAssuranceUpdate)
        }
    }

    // Map the type to the respective handler
    const handlers = {
        realclosedupdates: handleRealClosedUpdates,
        bidAddtime: handleBidAddTime,
        watchlistAdded: handleWatchlistUpdate,
        cancelbidemitted: handleCancelBidEmitted,
        buyer_assurance: handleBuyerAssurance,
    }

    const handler = handlers[type]
    if (handler) handler()
}

export const messageHandlerSingle = (
    data,
    product,
    user,
    setAlert,
    setViewProduct,
    type,
    showAlert,
) => {
    console.log('Message --', data)
    if (user) {
        // console.log('user inside singlee', user)
        let user_id = user.id
        const index = product.id === parseInt(data.id || data.project_id, 10)
        let productToChange = product
        // console.log('data', data)
        if (index) {
            if (type === 'realclosedupdates') {
                if (data.usr !== '') {
                    if (user_id === parseInt(data.bpop_cbidder || data.usr)) {
                        product = {
                            ...productToChange,
                            market_status: 'sold',
                            bidtopstatus: 'won',
                            buynowamount: data.buynowamount,
                            cbidtext: 'Sold Price',
                            buynowed: data.bpop_cbidder,
                            buynowpaid: 0,
                            common_invoice: data.common_invoice,
                            socket_change: 1,
                        }
                        setViewProduct(product)
                    } else if (parseInt(productToChange.bid_or_not) > 0) {
                        product = {
                            ...productToChange,
                            market_status: 'sold',
                            bidtopstatus: 'lost',
                            buynowamount: data.buynowamount,
                            cbidtext: 'Sold Price',
                            buynowed: data.bpop_cbidder,
                            buynowpaid: 0,
                            socket_change: 1,
                        }
                        setViewProduct(product)
                    } else {
                        product = {
                            ...productToChange,
                            market_status: 'sold',
                            buynowamount: data.buynowamount,
                            cbidtext: 'Sold Price',
                            socket_change: 1,
                        }
                        setViewProduct(product)
                    }
                } else {
                    if (parseInt(productToChange.bid_or_not) > 0) {
                        product = {
                            ...productToChange,
                            market_status: 'closed',
                            bidtopstatus: 'lost',
                            socket_change: 1,
                        }
                        setViewProduct(product)
                    } else {
                        product = {
                            ...productToChange,
                            market_status: 'closed',
                            socket_change: 1,
                        }
                        setViewProduct(product)
                    }
                }
            } else if (type === 'bidAddtime') {
                console.log('Socket Handler', data)
                let date_closed = product.date_closed
                if (parseInt(data.bpop_belowFive) === 1) {
                    date_closed = data.bpop_belowFiveIncrement
                }
                if (data.status === 'failed' && user_id === parseInt(data.bpop_cbidder)) {
                    setAlert(data.error, 'error')
                }
                if (data.status !== 'failed') {
                    let maxbidamt = product.maxbidamt
                    if (user_id === parseInt(data.bpop_cbidder)) {
                        setAlert('Bid placed successfully', 'success')
                        if (maxbidamt < parseFloat(data.bidAmount)) {
                            maxbidamt = data.bidAmount
                        }
                        if (data.bpop_bidcount == 1 && data.bpop_wprice == 1) {
                            maxbidamt = data.bpop_wprice_morehigh
                        }
                        if (data.bpop_orgwsprice && data.bpop_orgwsprice != '') {
                            maxbidamt = data.bpop_orgwsprice
                        }

                        if (parseInt(user_id) === parseInt(data.bpop_higher)) {
                            if (parseInt(product.rprice || 0) <= parseInt(data.bpop_wprice)) {
                                if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                    data.bpop_bidstatus = `You are winning this item at $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()}. `
                                } else {
                                    data.bpop_bidstatus = `You are winning this item at $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()}. Your autoBid of $${parseFloat(
                                        data.bpop_wprice_morehigh,
                                    ).toUSFormat()} has been placed successfully.`
                                }
                                setAlert('You are winning this item!', 'success')
                            } else {
                                if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                    data.bpop_bidstatus = `Your bid of $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                } else {
                                    data.bpop_bidstatus = `Your bid of $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()} and auto bid of $${parseFloat(
                                        data.bpop_wprice_morehigh,
                                    ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                }
                                setAlert(
                                    'You are losing this item. Because of reserve price not yet met!',
                                    'error',
                                )
                            }
                        } else if (data.bpop_bidstatus.includes('losing')) {
                            data.bpop_bidstatus = `You've been outbid on this item. Current leading bid is $${parseFloat(
                                data.bpop_wprice,
                            ).toUSFormat()}`
                            setAlert("You've been outbid on this item.", 'error')
                        }

                        product = {
                            ...productToChange,
                            next_bid: data.bpop_cuser_nextbid,
                            wprice: data.bpop_wprice,
                            latestbid: data.bpop_wprice,
                            bidtopstatus: data.bpop_bidstatus,
                            nobids: data.bpop_bidcount,
                            incrementamt: data.bpop_cuser_increment,
                            bid_or_not: 1,
                            date_closed,
                            maxbidamt: maxbidamt,
                            bid_count: data.bpop_bidcount,
                            bids: data.bpop_bidcount,
                            cbidtext: 'Current Bid',
                            socket_change: 1,
                        }
                        setViewProduct(product)
                    } else {
                        // console.log(
                        //     parseInt(productToChange.cbidnot),
                        //     'parseInt(productToChange.bid_or_not) > 0',
                        // )
                        if (
                            parseInt(productToChange.bid_or_not) > 0 ||
                            parseInt(productToChange.cbidnot) > 0
                        ) {
                            if (user_id === parseInt(data.bpop_higher)) {
                                if (
                                    parseInt(productToChange.next_bid) < parseInt(data.bpop_nextbid)
                                ) {
                                    if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                        data.bidtopstatus = `You are winning this item at $${parseFloat(
                                            data.bpop_wprice,
                                        ).toUSFormat()}.`
                                    } else {
                                        data.bidtopstatus = `You are winning this item at $${parseFloat(
                                            data.bpop_wprice,
                                        ).toUSFormat()}. Your autoBid of $${parseFloat(
                                            data.bpop_wprice_morehigh,
                                        ).toUSFormat()} has been placed successfully.`
                                    }
                                    product = {
                                        ...productToChange,
                                        latestbid: data.bpop_wprice,
                                        nobids: data.bpop_bidcount,
                                        incrementamt: productToChange.incrementamt,
                                        next_bid: data.bpop_nextbid_org,
                                        wprice: data.bpop_wprice,
                                        bidtopstatus: data.bidtopstatus,
                                        bid_count: data.bpop_bidcount,
                                        bids: data.bpop_bidcount,
                                        date_closed,
                                        socket_change: 1,
                                    }
                                    setViewProduct(product)
                                } else {
                                    if (
                                        parseInt(product.rprice || 0) <= parseInt(data.bpop_wprice)
                                    ) {
                                        if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                            data.bidtopstatus = `You are winning this item at $${parseFloat(
                                                data.bpop_wprice,
                                            ).toUSFormat()}.`
                                        } else {
                                            data.bidtopstatus = `You are winning this item at $${parseFloat(
                                                data.bpop_wprice,
                                            ).toUSFormat()}. Your autoBid of $${parseFloat(
                                                data.bpop_wprice_morehigh,
                                            ).toUSFormat()} has been placed successfully.`
                                        }
                                    } else {
                                        if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                            data.bidtopstatus = `Your bid of $${parseFloat(
                                                data.bpop_wprice,
                                            ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                        } else {
                                            data.bidtopstatus = `Your bid of $${parseFloat(
                                                data.bpop_wprice,
                                            ).toUSFormat()} and auto bid of $${parseFloat(
                                                data.bpop_wprice_morehigh,
                                            ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                        }
                                    }
                                    product = {
                                        ...productToChange,
                                        latestbid: data.bpop_wprice,
                                        nobids: data.bpop_bidcount,
                                        incrementamt: productToChange.incrementamt,
                                        wprice: data.bpop_wprice,
                                        bidtopstatus: data.bidtopstatus,
                                        bid_count: data.bpop_bidcount,
                                        bids: data.bpop_bidcount,
                                        date_closed,
                                        socket_change: 1,
                                    }
                                    setViewProduct(product)
                                }
                            } else {
                                product = {
                                    ...productToChange,
                                    latestbid: data.bpop_wprice,
                                    nobids: data.bpop_bidcount,
                                    incrementamt: data.bpop_increment,
                                    next_bid: data.bpop_nextbid_org,
                                    wprice: data.bpop_wprice,
                                    bid_count: data.bpop_bidcount,
                                    bids: data.bpop_bidcount,
                                    bidtopstatus: `You've been outbid on this item. Current leading bid is $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()}`,
                                    bid_or_not: 1,
                                    date_closed,
                                    socket_change: 1,
                                }
                                setViewProduct(product)
                            }
                        } else {
                            product = {
                                ...productToChange,
                                latestbid: data.bpop_wprice,
                                nobids: data.bpop_bidcount,
                                incrementamt: data.bpop_increment,
                                next_bid: data.bpop_nextbid_org,
                                wprice: data.bpop_wprice,
                                bid_count: data.bpop_bidcount,
                                bids: data.bpop_bidcount,
                                cbidtext: 'Current Bid',
                                date_closed,
                                socket_change: 1,
                            }
                            setViewProduct(product)
                        }
                    }
                    // getBidHistoryProduct({ product_id: project_id })
                }
            } else if (type === 'watchlistAdded') {
                product = {
                    ...productToChange,
                    watchlistid: data.status === 'added' ? 1 : 0,
                    socket_change: 1,
                }
                setViewProduct(product)
            } else if (type === 'cancelbidemitted') {
                console.log(data, 'cancellllllllllll')
                if (Object.keys(data).length) {
                    // console.log('current id vs new lot id')

                    if (parseInt(data.project_id) === parseInt(productToChange.id)) {
                        if (parseInt(user_id) === parseInt(data.prodata.user_id)) {
                            if (parseInt(product.rprice || 0) <= parseInt(data.bpop_wprice)) {
                                if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                    data.bpop_bidstatus = `You are winning this item at $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()}. `
                                } else {
                                    data.bpop_bidstatus = `You are winning this item at $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()}. Your autoBid of $${parseFloat(
                                        data.bpop_wprice_morehigh,
                                    ).toUSFormat()} has been placed successfully.`
                                }
                                setAlert('You are winning this item!', 'success')
                            } else {
                                if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                    data.bpop_bidstatus = `Your bid of $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                } else {
                                    data.bpop_bidstatus = `Your bid of $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()} and auto bid of $${parseFloat(
                                        data.bpop_wprice_morehigh,
                                    ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                }
                                setAlert(
                                    'You are losing this item. Because of reserve price not yet met!',
                                    'error',
                                )
                            }
                        }
                        // if (data.prodata.user_id === user?.id) {
                        //     data.bpop_bidstatus = `You are winning this item at $${parseFloat(
                        //         data.bpop_wprice,
                        //     ).toUSFormat()}. `
                        // }
                        else {
                            data.bpop_bidstatus = `You've been outbid on this item.`
                        }

                        product = {
                            ...productToChange,
                            bids: data.bpop_bidcount,
                            incrementamt: data?.currentbid_increment,
                            bidtopstatus: data.bpop_bidstatus,
                            nobids: data.bpop_bidcount,
                            wprice: data.bpop_wprice,
                            next_bid:
                                data.bpop_bidcount == 0 ? data.bpop_wprice : data.bpop_nextbid,
                            latestbid: data.bpop_bidcount == 0 ? null : data.bpop_wprice,
                            socket_change: 1,
                        }
                        console.log('product', product)
                        setViewProduct(product)
                    }
                }
            }
        }
    }
}

export const messageHandlerAuction = (data, auction, user, setAlert, setViewAuction, type) => {
    let user_id = user.id
    if (data.bpop_auctionid) {
        const index = auction.findIndex((s) => s.id === parseInt(data.bpop_auctionid, 10))
        let auctionToChange = auction[index]
        if (index !== -1) {
            if (type === 'bidAddtime') {
                let date_closed = auction[index].date_closed
                if (parseInt(data.bpop_belowFiveAuction) === 1) {
                    date_closed = data.bpop_belowFiveIncrement
                }
                if (data.status !== 'failed') {
                    auction[index] = {
                        ...auctionToChange,
                        date_closed,
                    }
                    setViewAuction([...auction])
                }
            }
        }
    }
}

export const messageHandleBuynow = (
    data,
    product,
    user,
    setAlert,
    setViewCartItems,
    type,
    setRemoveid,
) => {
    if (user) {
        // console.log('user inside', user)
        let user_id = user.id
        if (type === 'realclosedupdatesbuynow') {
            var index = product.findIndex((s) => s.product_id === parseInt(data.pid, 10))
        } else {
            var index = product.findIndex((s) => s.product_id === parseInt(data.id, 10))
        }
        // console.log('data', data)
        if (index !== -1) {
            if (type === 'realclosedupdatesbuynow') {
                if (parseInt(user_id) != parseInt(data.usr)) {
                    setAlert(
                        'Sorry,Some One Has Bought Your Item Or Item End time Reached for ' +
                            product[index].title +
                            '.So It Removed From Your Cart.',
                        'error',
                    )
                    product.splice(index, 1)
                    setViewCartItems([...product])
                }
            }
            if (type === 'checkbuynowavailable') {
                // console.log('innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn')
                if (parseInt(data.bpop_wprice) >= parseInt(product[index].bprice)) {
                    // setAlert(
                    //     'Sorry,Bid Amount Exceeded From Buynow Price' +
                    //         product[index].title +
                    //         '.So It Removed From Your Cart.',
                    //     'error',
                    // )
                    // setRemoveid(product[index].product_id)
                    //product.splice(index, 1)
                    product[index].wprice = data.bpop_wprice
                    setViewCartItems([...product])
                }
            }
        }
    }
}

export const qtyHandleBuynow = (data, product, user, setViewCartItems, type) => {
    if (user) {
        // console.log('user inside', user)

        var index = product.findIndex((s) => s.id === parseInt(data.pid, 10))

        // console.log('data', data)
        if (index !== -1) {
            product[index].qty = data.remaining_qty
            setViewCartItems([...product])
        }
    }
}

export const qtyHandleBuynowsingle = (data, product, user, setViewCartItems, type) => {
    if (user) {
        let productToChange = product
        if (parseInt(product.id) == parseInt(data.pid)) {
            product = {
                ...productToChange,
                qty: data.remaining_qty,
            }
            setViewCartItems(product)
        }
    }
}

export const timeHandler = (data, product, user, setAlert, setViewProduct, type, showAlert) => {
    // if (type == 'singletimehandler') {
    //     if (new Date(product.date_added) > new Date()) {
    //         let productdata = product
    //         productdata.market_status = 'future'
    //         setViewProduct(productdata)
    //     }
    // }
}

export const followHandler = (socketData, setLocalData, user, user_id, setFollowed) => {
    // console.log(socketData, user, user_id)
    if (parseInt(user_id) === parseInt(socketData.user_id)) {
        if (parseInt(socketData.user) === parseInt(user?.id)) {
            setFollowed(socketData.followed)
        }
        setLocalData((prev) => ({
            ...prev,
            follow_count: socketData.follow_count,
            unfollow_count: socketData.unfollow_count,
        }))
    }
}

export const make_an_offer_update_socket = (
    data,
    product,
    user,
    setAlert,
    setViewProduct,
    type,
) => {
    if (parseInt(data.project_id) == parseInt(product.id)) {
        var product_update = product
        product = {
            ...product_update,
            market_status: 'sold',
            socket_change: 1,
        }
        setViewProduct(product)
    }
}

export const make_an_offer_update_socket_multiple = (
    data,
    product,
    user,
    setAlert,
    setViewProduct,
    type,
) => {
    var index = product.findIndex((s) => s.id === parseInt(data.project_id, 10))
    if (index != -1) {
        var product_update = product
        product_update[index].market_status = 'sold'
        setViewProduct([...product_update])
    }
}

export const reverse_auction_item = (data, product, user, setAlert, setViewProduct, type) => {
    if (type == 'multiple') {
        var index = product.findIndex((s) => s.id === parseInt(data.id, 10))
        if (index != -1) {
            var product_update = product
            product_update[index].wprice = data.wsprice
            product_update[index].market_status = 'sold'
            setViewProduct([...product_update])
        }
    }

    if (type == 'single') {
        var product_update = product
        product_update.wprice = data.wsprice
        product_update.market_status = 'sold'
        setViewProduct(product_update)
    }
}
